<template>
  <div class="project-wrapper">
    <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="100px" v-loading="loading">
      <el-form-item label="项目名称" prop="customerName">
        <el-input size="medium" v-model="form.customerName" :disabled="status"/>
      </el-form-item>
      <el-form-item label="项目邮箱" prop="email">
        <el-input size="medium" v-model="form.email" />
      </el-form-item>
      <el-form-item label="客户电话" prop="phone">
        <el-input size="medium" v-model="form.phone" />
      </el-form-item>
      <el-form-item label="管理员账号" :hidden="status" prop="username">
        <el-input size="medium" v-model="form.username" />
      </el-form-item>
      <el-form-item label="管理员密码" :hidden="status" prop="password">
        <el-input size="medium" show-password v-model="form.password" />
      </el-form-item>
      <el-form-item label="所属行业" prop="industryName">
        <el-select size="medium" v-model="form.industryName" :disabled="status">
          <el-option v-for="item in industryList" :label="item.name" :value="item.name" :key="item.name">{{ item.name }}</el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="采集范围" class="range">
        <el-checkbox-group v-model="form.range" @change="selectChange">
          <el-checkbox v-for="item in checkboxList" :label="item" :key="item">{{item}}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="客户类型" prop="pubDate" class="type">
        <el-radio-group v-model="form.useType" size="mini">
          <el-radio v-model="radio" label="0">试用</el-radio>
          <el-radio v-model="radio" label="1">正式</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="客户类型" prop="isUrgent" class="type">
        <el-radio-group v-model="form.isUrgent" size="mini">
          <el-radio v-model="radio" label="0">普通</el-radio>
          <el-radio v-model="radio" label="1">紧急</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="客户类型" prop="isUrgent" class="type">
        <el-radio-group v-model="form.isAuto" size="mini">
          <el-radio v-model="radio" label="0">半自动处理</el-radio>
          <el-radio v-model="radio" label="1">全自动处理</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="数据保存期限" prop="pubDate" class="limit">
        <el-radio-group v-model="form.expiredTime" size="mini">
          <el-radio label="90" border>90天</el-radio>
          <el-radio label="180" border>180天</el-radio>
          <el-radio label="365" border>365天</el-radio>
<!--          <el-radio v-model="radio" label="4" border>自定义</el-radio>-->
          <!-- <span class="dateContainer" :style="pubDateStyle">
              【
              <span class="beginTime">{{form.date1}}</span>
              ~
              <span class="endTime">{{form.date2}}</span>
              】
            </span> -->
        </el-radio-group>
      </el-form-item>
      <el-form-item label="自动报告" style="width: 100%">
        <el-switch
          v-model="form.isAutoDaily"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-value="1"
          inactive-value="0">
        </el-switch>
      </el-form-item>
<!--      <el-form-item label="生成时间" v-if="form.isAutoDaily === '1'" style="width: 100%">-->
<!--        <el-time-select-->
<!--          placeholder="起始时间"-->
<!--          v-model="form.dailyTime"-->
<!--          value-format="HH:mm"-->
<!--          :picker-options="{-->
<!--              start: '00:00',-->
<!--              step: '00:60',-->
<!--              end: '23:00'-->
<!--            }">-->
<!--        </el-time-select>-->
<!--      </el-form-item>-->
      <el-form-item label="自动情报">
        <el-switch
          v-model="form.isAutoIntelligence"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-value="1"
          inactive-value="0">
        </el-switch>
        <span style="margin-left: 20px;color: slategray">自动情报功能是将系统中监测到的负面数据自动加入到情报中心</span>
      </el-form-item>
      <el-form-item style="width: 100%;">
        <el-button size="medium" @click="goBack">返回</el-button>
        <el-button type="primary" size="medium" @click="saveCus">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import eventHub from '@/utils/eventHub'
import { showProject, updateProjcet, addCus, allIndustry } from '@/api/settings/project'
export default {
  mounted () {
    this.showAllIndustry()
    if (this.$route.meta.type === 'edit') {
      this.showProjectInfo()
      eventHub.$emit('msg', '修改项目')
    } else if (this.$route.meta.type === 'add') {
      this.status = false
      eventHub.$emit('msg', '创建项目')
    }
  },
  data () {
    return ({
      status: true,
      loading: false,
      form: {
        customerName: '',
        email: '',
        phone: '',
        industryName: '',
        range: ['网媒', '贴吧', '微信', '博客', '论坛', '电子报', '微博', '全网', '问答', '客户端', '视频'],
        useType: '0',
        expiredTime: '365',
        isUrgent: '0',
        isAuto: '1',
        isAutoDaily: '',
        dailyTime: '',
        isAutoIntelligence: '1'
      },
      rules: {
        customerName: [
          { required: true, message: '项目名称不能为空', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '邮箱不能为空', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '电话号码不能为空', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的电话号码', trigger: 'blur' }
        ],
        username: [
          { required: true, message: '管理员账户不能为空', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '管理员密码不能为空', trigger: 'blur' },
          { min: 6, message: '密码长度不低于6位', trigger: 'blur' }
        ],
        industryName: [
          { required: true, message: '所属行业不能为空', trigger: 'change' }
        ]
      },
      industryList: [],
      checkboxList: ['网媒', '贴吧', '微信', '博客', '论坛', '电子报', '微博', '全网', '问答', '客户端', '视频']
    })
  },
  methods: {
    selectChange () {
      this.$forceUpdate()
    },
    goBack () {
      this.$router.push('/settings/project/')
    },
    async showProjectInfo () {
      this.loading = true
      const res = await showProject({ cid: this.$route.params.id })
      this.form = res.data.res
      this.form.range = res.data.range
      this.form.expiredTime = res.data.res.expiredTime += ''
      this.form.isUrgent += ''
      this.form.isAuto += ''
      this.form.isAutoDaily += ''
      this.form.isAutoIntelligence += ''
      this.loading = false
    },
    update () {
      let index = 0
      this.$refs['form'].validateField([ 'customerName', 'email', 'phone' ], async (error) => {
        if (!error) {
          index++
        } else {
          console.log('error submit!!')
          return false
        }
        if (index === 3) {
          const res = await updateProjcet(this.form)
          if (res.code === 0) {
            this.$message.success('修改成功')
            this.showProjectInfo()
          } else {
            this.$message.error('修改失败')
          }
        }
      });
    },
    async addCustome () {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const res = await addCus(this.form)
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.$router.push('/settings/project/')
          } else {
            this.$message.error(res.msg)
          }

        } else {
          console.log('error submit!!')
          return false
        }
      });
    },
    async showAllIndustry () {
      const res = await allIndustry()
      this.industryList = res.data.list
    },
    saveCus () {
      if (this.$route.meta.type === 'edit') {
        this.update()
      } else if (this.$route.meta.type === 'add') {
        this.addCustome()
      }
    }
  }
}
</script>
<style type="text/css">
  .limit .el-radio__input{
    display: none;
  }
</style>
<style lang="scss" scoped>
  .project-wrapper {
    width: 100%;
    border-radius: 2px;
    background: #fff;
    padding-top: 50px;

    .el-form {
      display: flex;
      flex-wrap: wrap;
      padding: 0 30px;

      .el-form-item {
        width: 50%;

        .el-input {
          width: 80%;
        }

        &.words,
        &.type,
        &.limit {
          width: 100%;
        }

        &.range {
          width: 80%;
        }

        textarea {
          width: 100%;
          height: 136px;
          border: 1px solid #DCDFE6;
          color: #606266;
          border-radius: 4px;
        }

        .rules {
          color: #8f8f94;
          font-size: 12px;
          line-height: 22px;
          margin: 0;
        }

      }
    }
  }
</style>
